<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" md="3">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>

  <v-col cols="12" md="3">
<export-excel
            v-if="desserts.length > 0"
            :data="desserts"
            type="button"
            name="Basic Report"
          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
 </v-col>

      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <template v-slot:top>
          <v-card flat>
            <v-card-actions class="py-1 px-0">
              <v-spacer></v-spacer>
              <div style="width: 400px">
                <v-text-field
                  v-model="search"
                  dense
                  solo-inverted
                  flat
                  label="Search"
                  hide-details                  
                ></v-text-field>
              </div>
            </v-card-actions>
          </v-card>
        </template>
        <!-- @click="BtnApprove(index)" -->
        <template v-slot:item="{ item }">
          <tr>
            <td style="text-align: left; width: 50px">
              {{ item.app_id }}
            </td>
            <td style="text-align: left; width: 65px">
              <img
                :src="$apiPath + item.picHeadShot"
                height="60px"
                width="60px"
                style="border-radius: 10px; border: 1px solid #ccc"
                type="button"
              />
            </td>
            <td>{{ item.qrcode }}</td>
            <td>{{ item.jobtype_name }}</td>
            <td>{{ item.fName }}</td>
            <td>{{ item.print_text }}</td>
            <td>{{ item.card_name }}</td>
            <td>{{ item.status_named }}</td>
            <td>{{ item.reason_name }}</td>

            <td>
              <v-btn
                medium
                color="success"
                v-if="item.status_named == 'Cancelled'"
                @click="
                  cancelcard(
                    item.application_id,
                    item.status_named,
                    7,
                    item.app_id
                  )
                "
              >
                <v-icon left>mdi-account-reactivate</v-icon>ACTIVE</v-btn
              >

              <v-btn
                medium
                color="primary"
                v-if="item.status_named != 'Cancelled'"
                @click="
                  cancelcard(
                    item.application_id,
                    item.status_named,
                    5,
                    item.app_id
                  )
                "
              >
                <v-icon left>mdi-close-circle</v-icon>CANCEL</v-btn
              >
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogcancel" persistent max-width="450px">
      <v-card class="mx-auto" max-width="450px" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              Application ID - {{ cancelLoad.AppID }}
            </div>

            <v-list-item-title class="mb-4">
              {{ cancelLoad.company_name }}
            </v-list-item-title>

            <v-list-item-title class="mb-4">
              {{ cancelLoad.Fname }}
            </v-list-item-title>

            <v-list-item-title class="mb-4">
              {{ cancelLoad.card_name }}
            </v-list-item-title>

            <v-list-item-title class="mb-4">
              <v-select
                label="Select Reason"
                v-model="SelReasonForUpdate"
                :items="ResonForUpdate"
                item-text="reason_name"
                item-value="reason_id"
                outlined
                hide-details
                dense
              ></v-select>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar
            tile
            size="80px"
            style="border-radius: 20px; border: 0px solid #ccc"
          >
            <img
              :src="$apiPath + cancelLoad.picHeadShot"
              height="80px"
              width="80px"
              style="border-radius: 20px; border: 1px solid #ccc"
            />
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <v-btn outlined color="primary" text> SAVE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialogcancel"
    >
      <template v-slot:default="dialogcancel">
        <v-card>
          <v-toolbar color="primary" dark
            >Application ID - {{ APPID_FOR_CANCEL_DISPLAY }}</v-toolbar
          >
          <v-card-text>
            <div class="text-h5 pa-5">{{ statusmsg }}</div>
            <v-select
              label="Select Reason"
              v-model="SelReasonForUpdate"
              :items="ResonForUpdate"
              item-text="reason_name"
              item-value="reason_id"
              outlined
              hide-details
              dense
            ></v-select>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogcancel.value = false"
              >NO</v-btn
            >
            <v-btn color="success" @click="btnsave">YES</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  components: {},
  data() {
    return {
      statusmsg: "",
      status_id_ckeck: "",
      dialogcancel: false,
      totalprintqu: "0",
      Printflag: "PRINT IN QUEUE",
      searchprintqueue: "",
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: false,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "",
      Status: [],

      SelstatusForUpade: "",
      StatusForUpdate: [],

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],
      APPID_FOR_CANCEL: "",
      APPID_FOR_CANCEL_DISPLAY: "",

      //companytype: [],
      companytype_id: [],
      postforGetdata: {
        event_id: "",
        venue_id: "",
        vendor_id: "",
      },

      postData: {
        application_id: "",
        event_id: "",
        venue_id: "",
        status_id: "",
        reason_id: "",
      },

      headers: [
        {
          text: "AppId",
          align: "left",
          value: "application_id",
          class: "blue lighten-5",
          sortable: false,
        },
        {
          text: "Photo",
          align: "center",
          class: "blue lighten-5",
        },

 {
          text: "Qrcode",
          value: "qrcode",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Name",
          value: "fName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Zone",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Card Name",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Reason",
          value: "reason_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Action",
          align: "left",
          class: "blue lighten-5",
        },
      ],
      desserts: [],
      desserts1: [],
      dessertscancelload: [],

      cancelLoad: {
        AppID: "",
        Fname: "",
        card_name: "",
        company_name: "",
        picHeadShot: "",
      },

      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    // sessionStorage.display_event_name = "IPL T20 2021";
    // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    //sessionStorage.EVENTID = "1";
    // sessionStorage.VENUEID = "1";
    // sessionStorage.VENDORID = "1";

    this.$store.commit("setPageTitle", "Active / Cancel Card");
    this.$store.commit("setPageTitleIcon", "mdi-account-cancel");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
   // this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    cancelcard: function (AppID, status, status_id, APPID_DISPLAY) {
      this.status_id_ckeck = "";
      this.status_id_ckeck = status_id;

      this.bindReson(this.status_id_ckeck);
      if (status == "Cancelled") {
        this.statusmsg = "Do you want to Reactive card !";
      } else {
        this.statusmsg = "Do you want to cancel card !";
      }

      this.APPID_FOR_CANCEL = AppID;
      this.APPID_FOR_CANCEL_DISPLAY = APPID_DISPLAY;
      this.dialogcancel = true;
    },

    bindReson: async function (StatusID) {
      await this.$axios
        .get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" + StatusID)
        .then((res) => {
          this.ResonForUpdate = res.data.result;
        });
    },
    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
    },

    btnsearch: function () {
      if (this.SelCompany.length <= 0) {
        this.showSnackbar("error", "Select Company !");
        return;
      }

      this.appstatus = this.Selstatus;
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.SelCompany
      );
    },

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get("vendor/GetByEventId/" + EventID + "/" + VenueID + "/" + this.$store.state.eventInfo.EventAdminID)
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
            this.Company.splice(0, 0, { company_id: "0", company_name: "All" });
          }
        });
    },

    Reload: async function (event_id, venue_id, vendor_id) {
      //  this.loadingstatus = true;
//alert(event_id + "/" + venue_id + "/" + vendor_id)
      this.overlay = true;
      await this.$axios
        .get(
            "Application/forCancel/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            vendor_id
        )
        .then((res) => {
          this.desserts = res.data.result;
console.log(res.data.result);
          this.loadingstatus = false;
          this.overlay = false;
        })
        .catch()
        .finally();
    },

    btnsave: async function () {
      (this.postData.application_id = this.APPID_FOR_CANCEL),
        (this.postData.event_id = this.$store.state.eventInfo.EventId);
      this.postData.venue_id = this.$store.state.eventInfo.VenueId;
      if (this.status_id_ckeck != "5") {
        this.postData.status_id = "7";
        this.postData.reason_id = "0";
      } else {
        this.postData.status_id = "5";
        this.postData.reason_id = this.SelReasonForUpdate;
      }

      await this.$axios
        .put("Application/asCancel", this.postData)
        .then((res) => {
          if (res.data.status == "Success") {
            // alert("data updated");
            this.dialogcancel = false;
            this.showSnackbar("Success", "Application Updated!");
            this.Reload(
              this.$store.state.eventInfo.EventId,
              this.$store.state.eventInfo.VenueId,
              this.SelCompany
            );
          }
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style>
</style>